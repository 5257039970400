<template>
    <div class="row">
        <div class="col-12 mb-3 mt-5">
            <b-card header="Simple Claim Record">
                <div class="pr-3 pt-3 pb-3 pl-3">
                    <b-row>
                        <b-col cols="3">
                            <v-date-picker
                                v-model="dateRange"
                                :model-config="modelConfig"
                                timezone="UTC"
                                is-range
                                is24hr
                                @input="getSimpleClaimRecord(true)"
                            >
                                <template v-slot="{ inputValue, togglePopover }">
                                    <b-form-group>
                                        <b-input-group v-on:click="togglePopover">
                                            <b-input-group-prepend is-text>
                                                <feather-icon icon="CalendarIcon" />
                                            </b-input-group-prepend>
                                            <b-form-input :value="dateToString(inputValue)" @keypress.prevent />
                                        </b-input-group>
                                    </b-form-group>
                                </template>
                            </v-date-picker>
                        </b-col>
                    </b-row>

                    <b-row class="mb-3">
                        <b-col cols="6">
                            <b-form-input
                                id="wallet address"
                                label="text"
                                :clearable="true"
                                placeholder="wallet address"
                                v-model="queryCondition.walletAddress"
                            />
                        </b-col>
                    </b-row>

                    <b-row class="mb-3">
                        <b-col cols="3">
                            <b-form-input id="sign id" label="text" :clearable="true" placeholder="sign id" v-model="queryCondition.signId" />
                        </b-col>

                        <b-col cols="3">
                            <b-form-input placeholder="User id" v-model="queryCondition.userId" />
                        </b-col>
                    </b-row>

                    <b-row class="mb-3">
                        <b-col cols="3">
                            <v-select
                                id="status"
                                label="text"
                                multiple
                                taggable
                                push-tags
                                :clearable="true"
                                :reduce="(text) => text.value"
                                placeholder="Status"
                                v-model="queryCondition.statusArray"
                                :options="claimStatusOptions"
                            />
                        </b-col>

                        <b-col cols="3">
                            <v-select
                                id="chainType"
                                label="text"
                                multiple
                                taggable
                                push-tags
                                :clearable="true"
                                :reduce="(text) => text.value"
                                placeholder="Chain type"
                                v-model="queryCondition.chainType"
                                :options="chainTypeOptions"
                            />
                        </b-col>
                    </b-row>

                    <b-row class="mb-3">
                        <b-col cols="3">
                            <v-select
                                id="claimType"
                                label="text"
                                multiple
                                taggable
                                push-tags
                                :clearable="true"
                                :reduce="(text) => text.value"
                                placeholder="Claim type"
                                v-model="queryCondition.claimType"
                                :options="claimTypeOptions"
                            />
                        </b-col>

                        <b-col cols="1">
                            <b-button variant="secondary" @click="getSimpleClaimRecord(true)"
                                ><feather-icon icon="RotateCcwIcon" size="15"
                            /></b-button>
                        </b-col>
                    </b-row>

                    <b-row class="mb-3">
                        <b-col cols="3">
                            <b-button variant="primary" @click="$bvModal.show('upload-file-window')">Upload simple claim</b-button>
                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="claim_record_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-selected-rows-change="selectionChanged"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :select-options="{
                                enabled: false,
                            }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPage: 10,
                                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000, 10000],
                                setCurrentPage: 1,
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->
                                <span v-if="props.column.field === 'to_sign_amount'">
                                    {{ $tools.tokenAmountParser.parserToMoneyFormat(props.row[props.column.field], 18) }}
                                </span>

                                <span v-else-if="props.column.field === 'created_unixtime'">
                                    {{ moment.unix(props.row[props.column.field]).utc().format("YYYY-MM-DD HH:mm:ss") }}
                                </span>

                                <span v-else-if="props.column.field === 'status'">
                                    <b-badge v-if="props.row['status'] === 'onchain'" pill variant="primary" class="mr-3">{{
                                        props.row["status"]
                                    }}</b-badge>
                                    <b-badge v-else-if="props.row['status'] === 'received'" pill variant="success" class="mr-3">{{
                                        props.row["status"]
                                    }}</b-badge>
                                    <b-badge v-else-if="props.row['status'] === 'pending'" pill variant="info" class="mr-3">{{
                                        props.row["status"]
                                    }}</b-badge>
                                </span>

                                <span v-else>
                                    {{ props.row[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>

                    <!-- upload file window -->
                    <b-modal id="upload-file-window" size="m" title="Import simple claim" cancel-variant="secondary" cancel-title="Close" centered>
                        <b-overlay :show="addSimpleClaimVisible" rounded="sm">
                            <b-form>
                                <b-form-group>
                                    <label>Chain type:</label>
                                    <v-select
                                        id="chainType"
                                        label="text"
                                        :clearable="true"
                                        :reduce="(text) => text.value"
                                        placeholder="Chain type"
                                        v-model="selectChainType"
                                        :options="chainTypeOptions"
                                    />
                                </b-form-group>

                                <b-form-group>
                                    <label>Min amount:</label>
                                    <b-form-input id="minAmount" type="number" v-model="minAmount" disabled />
                                </b-form-group>

                                <b-form-group>
                                    <label>Max amount:</label>
                                    <b-form-input id="maxAmount" type="number" v-model="maxAmount" />
                                </b-form-group>

                                <b-form-group>
                                    <label>CSV file:</label>
                                    <input type="file" v-on:change="fileInput" />
                                </b-form-group>
                            </b-form>
                        </b-overlay>

                        <template #modal-footer>
                            <b-row class="w-100">
                                <b-col class="text-left"> </b-col>
                                <b-col cols="8" class="text-right">
                                    <b-button variant="secondary" @click="$bvModal.hide('upload-file-window')">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal>

                    <!-- upload confirm window -->
                    <b-modal
                        id="upload-confirm-window"
                        size="lg"
                        title="simple claim confirm"
                        cancel-variant="secondary"
                        cancel-title="Cancel"
                        centered
                    >
                        <b-overlay :show="false" rounded="sm">
                            <table class="vgt-table" ref="modalBody" style="border: none; font-size: 14px !important">
                                <thead>
                                    <tr>
                                        <th class="vgt-left-align text-center" align="center">Email</th>
                                        <th class="vgt-left-align text-center" align="center">Wallet Address</th>
                                        <th class="vgt-left-align text-center" align="center">Chain</th>
                                        <th class="vgt-left-align text-center" align="center">Claim type</th>
                                        <th class="vgt-left-align text-center" align="center">Amount</th>
                                    </tr>
                                </thead>
                                <tr v-for="record in targetList">
                                    <td align="center">{{ record.email }}</td>
                                    <td align="center">
                                        {{ record.wallet_address }}
                                    </td>
                                    <td align="center">{{ record.chain_type }}</td>
                                    <td align="center">
                                        {{ record.claim_type }}
                                    </td>
                                    <td align="center">{{ record.raw_amount }}</td>
                                </tr>
                            </table>
                        </b-overlay>

                        <template #modal-footer>
                            <b-row class="w-100">
                                <b-col class="text-left"> </b-col>
                                <b-col cols="8" class="text-right">
                                    <b-button variant="primary" class="mr-3" @click="uploadSimpleClaim">Confirm & Upload</b-button>
                                    <b-button variant="secondary" @click="$bvModal.hide('upload-confirm-window')">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormFile,
    BFormTags,
    BFormTextarea,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    VBTooltip,
    BTab,
    BTabs,
    BCardText,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import VueQr from "vue-qr";
import moment from "moment";
import VDatePicker from "v-calendar/lib/components/date-picker.umd";
import { parse } from "csv-parse/lib/sync";
import FileSaver from "file-saver";
import json2csv from "json2csv";
import tokenAmountParser from "../../tools/token_amount_parser";
import BigNumber from "bignumber.js";
const BN = require("bn.js");

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BInputGroupAppend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormFile,
        BFormTags,
        BFormTextarea,
        VueGoodTable,
        vSelect,
        BTab,
        BTabs,
        BCardText,

        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,
        VBTooltip,

        VueQr,
        VDatePicker,
    },

    methods: {
        log(...arg) {
            return console.log(...arg);
        },

        dateToString(inputValue) {
            return inputValue.start + " - " + inputValue.end;
        },

        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.getSimpleClaimRecord(true);
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.getSimpleClaimRecord(true);
        },

        selectionChanged(params) {
            let selectedRows = params.selectedRows;

            let totalAmount = new BN("0", 10);
            for (let i = 0; i < selectedRows.length; i++) {
                let rowAmount = new BN(selectedRows[i].amount, 10);
                totalAmount = totalAmount.add(rowAmount);
                console.log(totalAmount);
            }
            this.selectedAmount = totalAmount.toString(10);
        },

        ////////
        openClaimUploadWindow() {
            this.targetList = [];
            this.checkPass = false;
            this.addSimpleClaimVisible = true;
        },

        async uploadSimpleClaim() {
            // console.log(this.$store.state.auth.my_web_token);
            if (this.targetList.length == 0) {
                this.$bvToast.toast("no data to upload", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }
            if (!this.checkPass) {
                this.$bvToast.toast("data check not pass", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let resp = await this.$api.simpleClaim.importList(this.targetList, this.$store.state.auth.my_web_token);
            this.isQueryingRecord = false;
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            // console.log(resp.result.result);

            const csvData = [];
            for (let i = 0; i < resp.result.result.length; i++) {
                const el = resp.result.result[i];
                csvData.push({
                    email: el.email,
                    wallet_address: el.wallet_address,
                    chain_type: el.chain_type,
                    claim_type: el.claim_type,
                    amount: tokenAmountParser.parserToMoneyFormat(el.amount, 18),
                    error: el.error,
                });
            }
            const fields = ["email", "wallet_address", "amount", "chain_type", "claim_type", "error"];
            const BOM = "\uFEFF";
            const result = json2csv.parse(csvData, { fields: fields });
            //download
            var blob = new Blob([BOM + result], { type: "text/csv;charset=utf-8" });
            FileSaver.saveAs(blob, `meson_simple_claim_result.csv`);

            //
            this.getSimpleClaimRecord(true);

            this.$bvModal.hide("upload-confirm-window");
            this.$bvModal.hide("upload-file-window");
        },

        fileInput(event) {
            let file = event.target.files[0];
            var reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = async (e) => {
                this.targetList = [];
                // console.log(e.target);
                // console.log(e.target.result);

                const records = parse(e.target.result, {
                    columns: true,
                    skip_empty_lines: true,
                });
                // console.log(records);

                let useEmail = false;
                let useAddress = false;

                for (let i = 0; i < records.length; i++) {
                    if (records[i].email === "" && records[i].wallet_address === "") {
                        this.$bvToast.toast("email and wallet address all empty", {
                            title: `Error`,
                            variant: "danger",
                            solid: true,
                            toaster: "b-toaster-top-center",
                        });
                        event.target.value = "";
                        return;
                    }

                    // email and address cannot appear on the same table
                    if (records[i].email !== "") {
                        useEmail = true;
                    }
                    if (records[i].wallet_address !== "") {
                        useAddress = true;
                    }
                    if (useEmail && useAddress) {
                        this.$bvToast.toast("address and email cannot appear on the same table", {
                            title: `Error`,
                            variant: "danger",
                            solid: true,
                            toaster: "b-toaster-top-center",
                        });
                        event.target.value = "";

                        return;
                    }

                    if (records[i].chain_type === "" || records[i].chain_type != this.selectChainType) {
                        this.$bvToast.toast("chain type not exist", {
                            title: `Error`,
                            variant: "danger",
                            solid: true,
                            toaster: "b-toaster-top-center",
                        });
                        event.target.value = "";
                        return;
                    }

                    if (records[i].claim_type === "") {
                        this.$bvToast.toast("claim type not exist", {
                            title: `Error`,
                            variant: "danger",
                            solid: true,
                            toaster: "b-toaster-top-center",
                        });
                        event.target.value = "";
                        return;
                    }

                    const amount = parseFloat(records[i].amount);
                    if (isNaN(amount)) {
                        this.$bvToast.toast("amount is NaN", {
                            title: `Error`,
                            variant: "danger",
                            solid: true,
                            toaster: "b-toaster-top-center",
                        });
                        event.target.value = "";
                        return;
                    }
                    if (amount <= this.minAmount) {
                        this.$bvToast.toast("min amount error", {
                            title: `Error`,
                            variant: "danger",
                            solid: true,
                            toaster: "b-toaster-top-center",
                        });
                        event.target.value = "";
                        return;
                    }
                    if (amount > this.maxAmount) {
                        this.$bvToast.toast("max amount error", {
                            title: `Error`,
                            variant: "danger",
                            solid: true,
                            toaster: "b-toaster-top-center",
                        });
                        event.target.value = "";
                        return;
                    }

                    let amount_bn = new BigNumber(amount);
                    amount_bn = amount_bn.times(1e18);

                    let target = {
                        email: records[i].email,
                        wallet_address: records[i].wallet_address,
                        chain_type: records[i].chain_type,
                        claim_type: records[i].claim_type,
                        amount: amount_bn.toFixed(0),
                        raw_amount: amount,
                    };

                    this.targetList.push(target);
                }

                this.checkPass = true;

                event.target.value = "";
                this.confirm_w_open = true;
                this.$bvModal.show("upload-confirm-window");
            };
        },

        async getSimpleClaimRecord(fromDb) {
            if (this.pageMounted === false) {
                return;
            }
            if (this.isQueryingRecord === true) {
                return;
            }

            let startDate = moment(this.dateRange.start).utc().format("YYYY-MM-DD");
            let endDate = moment(this.dateRange.end).utc().format("YYYY-MM-DD");

            let statusArray =
                this.queryCondition.statusArray === null || this.queryCondition.statusArray.length === 0 ? null : this.queryCondition.statusArray;

            let chainTypeArray =
                this.queryCondition.chainType === null || this.queryCondition.chainType.length === 0 ? null : this.queryCondition.chainType;

            let claimTypeArray =
                this.queryCondition.claimType === null || this.queryCondition.claimType.length === 0 ? null : this.queryCondition.claimType;

            let userId = parseInt(this.queryCondition.userId);
            if (userId === null || isNaN(userId) || userId <= 0) {
                userId = null;
            }

            let walletAddress = this.queryCondition.walletAddress === "" ? null : this.queryCondition.walletAddress;
            let signId = this.queryCondition.signId === "" ? null : this.queryCondition.signId;

            this.table_show_overlay = true;
            this.isQueryingRecord = true;
            // query(id, statusArray, chainType, claimTypeArray, userId, toAddress, signId, signHex, startDate, endDate, limit, offset, fromDb, token);
            let resp = await this.$api.simpleClaim.query(
                null,
                statusArray,
                chainTypeArray,
                claimTypeArray,
                userId,
                walletAddress,
                signId,
                null,
                startDate,
                endDate,
                this.limit,
                this.offset,
                fromDb,
                this.$store.state.auth.my_web_token
            );
            this.isQueryingRecord = false;
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.row_data = resp.result.data;
            this.totalRows = resp.result.total_count;
        },
    },

    mounted() {
        this.pageMounted = true;
        this.getSimpleClaimRecord(true);
    },
    data() {
        return {
            moment: moment,
            isQueryingRecord: false,
            pageMounted: false,

            // upload simple claim
            selectChainType: "",
            minAmount: 0,
            maxAmount: 5000,
            targetList: [],
            checkPass: false,
            addSimpleClaimVisible: false,
            confirm_w_open: false,

            vsecs: 0,
            columns: [
                {
                    label: "ID",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Date",
                    field: "created_unixtime",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "User id",
                    field: "user_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Chain type",
                    field: "chain_type",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Claim type",
                    field: "claim_type",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Status",
                    field: "status",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Sign id",
                    field: "to_sign_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "To Wallet Address",
                    field: "to_sign_hex_address",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Sign amount",
                    field: "to_sign_amount",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Sign hex",
                    field: "sign_hex",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Contract Id",
                    field: "contract_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Operator Id",
                    field: "operator_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            row_data: [],
            limit: 10,
            offset: 0,
            totalRows: 0,

            //for update and delete
            focus: {},

            table_show_overlay: true,
            modal_row_view_overlay: false,
            add_price_show_overlay: false,
            on_chain_show_overlay: false,

            claimStatusOptions: [
                { text: "pending", value: "pending" },
                { text: "onchain", value: "onchain" },
                { text: "received", value: "received" },
            ],

            chainTypeOptions: [
                { text: "sepolia", value: "sepolia" },
                { text: "arbitrum", value: "arbitrum" },
                { text: "eth_mainnet", value: "eth_mainnet" },
            ],

            claimTypeOptions: [
                { text: "claimType1", value: "claimType1" },
                { text: "claimType2", value: "claimType2" },
                { text: "claimType3", value: "claimType3" },
            ],

            queryCondition: {
                statusArray: null,
                chainType: null,
                claimType: null,
                userId: null,
                walletAddress: null,
                signId: null,
            },

            dateRange: {
                start: new Date(new Date().valueOf() - 3600 * 24 * 365 * 1000),
                end: new Date(),
            },
            modelConfig: {
                start: {
                    timeAdjust: "00:00:00",
                },
                end: {
                    timeAdjust: "00:00:00",
                },
            },
        };
    },
};
</script>
